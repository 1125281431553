import React from "react";
import { enableMapSet } from "immer";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "data/store";
import * as Sentry from "@sentry/browser";
import App from "App";
import { ThemeProvider, GlobalStyles } from "@mui/material";
import { theme, globalStyles } from "config/theme";
import { BrowserRouter } from "react-router-dom";
import Toaster from "components/Toaster";
import { configureAxios } from "./config";
import "config/i18n";

enableMapSet();

Sentry.init({
  dsn: process.env?.REACT_APP_SENTRY_DSN,
  environment: process.env?.REACT_APP_ENVIRONMENT,
});
configureAxios();
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={globalStyles} />
        <App />
        <Toaster />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);
