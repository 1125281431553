export const PROCESSING_TYPES = {
  preProcessingSteps: "preProcessingSteps",
  ignoredSteps: "ignoredSteps",
  nonCatToolSteps: "nonCatToolSteps",
};

export const STATE_KEYS_TO_PROCESSING_TYPES = {
  preProcessingSteps: "PREPROCESSING",
  ignoredSteps: "IGNORED",
  nonCatToolSteps: "NON_CAT_TOOL",
};
