import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import {
  HOT_FOLDER,
  WORKING_COPY,
} from "pages/Panel/ConnectionProfiles/constants";
import {
  connectToXtm,
  fetchConnectionProfile,
  fetchConnectionProfiles,
  fetchCustomers,
  fetchWorkflowSteps,
} from "./connectionProfileApi";
import { fetchConnectionProfileMapper } from "./helpers/fetchConnectionProfileMapper";
import {
  allRequiredFieldsFilledWorkingCopy,
  allRequiredFieldsFilledHotFolder,
} from "./helpers/allRequiredFieldsFilled";
import { fetchConnectionProfileHotFolderMapper } from "./helpers/fetchConnectionProfileHotFolderMapper";

const initialState = {
  list: {
    isLoading: false,
    error: null,
    data: undefined,
    sort: {
      field: "id",
      order: "DESC",
    },
  },
  modify: {
    isLoading: false,
    error: null,
    form: {
      id: null,
      connectionProfileName: "",
      connectionType: null,
      active: true,
      clientAssigned: null,
      sharePoint: {
        isConnected: false,
        tokenID: null,
        loginCredentials: {
          sharePointUrl: "",
          sharePointClientID: "",
          sharePointClientSecret: "",
          sharePointTenantId: "",
          sharePointSiteName: "",
        },
        sharepointFolderPath: null,
        sharepointTargetPath: null,
      },
      xtm: {
        isConnected: false,
        tokenID: null,
        customers: [],
        customer: null,
        loginCredentials: {
          xtmUrl: "",
          xtmCompanyName: "",
          xtmUser: "",
          xtmPassword: "",
        },
        workflowSteps: {
          isLoading: false,
          error: null,
          selectedStepIds: [],
          preProcessingSteps: [],
          ignoredSteps: [],
          nonCatToolSteps: [],
          savedWorkflowSteps: {},
          data: [],
        },
        checkProjectMinutes: "",
        hotFolders: {
          templateType: null,
          xtmTemplate: null,
          xtmProjectNamePrefix: "",
          defaultDueDate: 0,
          continuousLocalisation: true,
          detectContentSpecificTime: null,
          contentSynchroFromShInterval: null,
          projectCreatedNotify: false,
          projectFinishedNotify: false,
          progressTranslationWorkflowNotify: false,
          projectCancelledNotify: false,
          emailAddress: "",
        },
      },
    },
    xtm: {
      isLoading: false,
      error: null,
      data: { tokenID: null },
    },
    customers: { isLoading: false, error: null, data: null },
  },
};

export const fetchWorkflowStepsAsync = createAsyncThunk(
  "connectionProfiles/fetchWorkflowSteps",
  async (payload) => {
    const response = await fetchWorkflowSteps(payload);
    return response.data;
  }
);

export const connectToXtmAsync = createAsyncThunk(
  "connectionProfiles/connectToXtm",
  async (payload) => {
    const response = await connectToXtm(payload);
    if (response.data.status === 400) throw new Error();
    return response.data;
  }
);

export const fetchCustomersAsync = createAsyncThunk(
  "connectionProfiles/fetchCustomers",
  async (payload) => {
    const response = await fetchCustomers(payload);
    return response.data;
  }
);

export const fetchConnectionProfilesAsync = createAsyncThunk(
  "connectionProfiles/fetchConnectionProfiles",
  async (params) => {
    const response = await fetchConnectionProfiles(params);
    return response.data;
  }
);

export const fetchConnectionProfileAsync = createAsyncThunk(
  "connectionProfiles/fetchConnectionProfile",
  async (params) => {
    const response = await fetchConnectionProfile(params);
    if (response.data.connection_type === HOT_FOLDER) {
      return fetchConnectionProfileHotFolderMapper(response.data);
    }
    return fetchConnectionProfileMapper(response.data);
  }
);

export const connectionProfilesSlice = createSlice({
  name: "connectionProfiles",
  initialState,
  reducers: {
    setConnectionType(state, action) {
      state.connectionType = action.payload;
    },
    setField(state, action) {
      set(state.modify.form, action.payload.field, action.payload.value);
    },
    resetState(state) {
      state.modify = initialState.modify;
    },
    clearConnectionProfile: (state) => {
      Object.assign(state, initialState);
    },
    updateSort(state, action) {
      state.list.sort = action.payload;
    },
  },
  extraReducers: {
    [fetchConnectionProfilesAsync.fulfilled]: (state, action) => {
      state.list.isLoading = false;
      state.list.error = null;
      state.list.data = action.payload;
    },
    [fetchConnectionProfilesAsync.pending]: (state) => {
      state.list.isLoading = true;
      state.list.error = null;
    },
    [fetchConnectionProfilesAsync.rejected]: (state, action) => {
      state.list.isLoading = false;
      state.list.error = action.error;
    },

    [fetchConnectionProfileAsync.fulfilled]: (state, action) => {
      state.modify.isLoading = false;
      state.modify.error = null;
      state.modify.form = action.payload;
    },
    [fetchConnectionProfileAsync.pending]: (state) => {
      state.modify.isLoading = true;
      state.modify.error = null;
    },
    [fetchConnectionProfilesAsync.rejected]: (state, action) => {
      state.modify.isLoading = false;
      state.modify.error = action.error;
    },

    [connectToXtmAsync.fulfilled]: (state, action) => {
      state.modify.xtm.isLoading = false;
      state.modify.xtm.error = null;
      state.modify.xtm.data = { tokenID: action.payload };
      state.modify.form.xtm.tokenID = action.payload;
    },
    [connectToXtmAsync.pending]: (state) => {
      state.modify.xtm.isLoading = true;
    },
    [connectToXtmAsync.rejected]: (state) => {
      state.modify.xtm.isLoading = false;
      state.modify.xtm.error = "Invalid credentials";
    },

    [fetchWorkflowStepsAsync.fulfilled]: (state, action) => {
      state.modify.xtm.workflowSteps.isLoading = false;
      state.modify.xtm.workflowSteps.error = null;
      state.modify.xtm.workflowSteps.data = action.payload;
    },
    [fetchWorkflowStepsAsync.pending]: (state) => {
      if (!state.modify.xtm) {
        state.modify.xtm = {};
      }

      if (!state.modify.xtm.workflowSteps) {
        state.modify.xtm.workflowSteps = {
          isLoading: false,
          error: null,
          data: [],
        };
      }

      state.modify.xtm.workflowSteps.isLoading = true;
    },

    [fetchWorkflowStepsAsync.rejected]: (state, action) => {
      if (!state.modify.xtm) {
        state.modify.xtm = {};
      }

      if (!state.modify.xtm.workflowSteps) {
        state.modify.xtm.workflowSteps = {
          isLoading: false,
          error: null,
          data: [],
        };
      }

      state.modify.xtm.workflowSteps.isLoading = false;
      state.modify.xtm.workflowSteps.error = action.error;
    },

    [fetchCustomersAsync.fulfilled]: (state, action) => {
      state.modify.customers.isLoading = false;
      state.modify.customers.error = null;
      state.modify.customers.data = action.payload;
      state.modify.xtm.error = null;
    },
    [fetchCustomersAsync.pending]: (state) => {
      state.modify.customers.isLoading = true;
    },
    [fetchCustomersAsync.rejected]: (state, action) => {
      state.modify.customers.isLoading = false;
      state.modify.customers.error = action.error;
      state.modify.xtm.error = "Invalid credentials";
    },
  },
});

export const selectConnectionProfilesData = ({ connectionProfiles }) =>
  connectionProfiles.list.data;
export const selectConnectionProfilesError = ({ connectionProfiles }) =>
  connectionProfiles.list.error;
export const selectConnectionProfilesIsLoading = ({ connectionProfiles }) =>
  connectionProfiles.list.isLoading;

export const selectAllXTMFieldsLength = ({ connectionProfiles }) =>
  Boolean(
    connectionProfiles.modify.form.xtm.loginCredentials.xtmCompanyName &&
      connectionProfiles.modify.form.xtm.loginCredentials.xtmPassword &&
      connectionProfiles.modify.form.xtm.loginCredentials.xtmUrl &&
      connectionProfiles.modify.form.xtm.loginCredentials.xtmUser
  );

export const selectAllSharePointFieldsLength = ({ connectionProfiles }) =>
  Boolean(
    connectionProfiles.modify.form.sharePoint.loginCredentials
      .sharePointClientID &&
      connectionProfiles.modify.form.sharePoint.loginCredentials
        .sharePointClientSecret &&
      connectionProfiles.modify.form.sharePoint.loginCredentials
        .sharePointUrl &&
      connectionProfiles.modify.form.sharePoint.loginCredentials
        .sharePointTenantId &&
      connectionProfiles.modify.form.sharePoint.loginCredentials
        .sharePointSiteName
  );

export const selectProfileData = ({ connectionProfiles }) =>
  connectionProfiles.modify.form;

export const selectProfileDataSharePoint = ({ connectionProfiles }) =>
  connectionProfiles.modify.form.sharePoint;

export const selectSharePointLoginCredentials = ({ connectionProfiles }) =>
  connectionProfiles.modify.form.sharePoint.loginCredentials;

export const selectModifyState = ({ connectionProfiles }) =>
  connectionProfiles.modify;

export const selectModifyForm = ({ connectionProfiles }) =>
  connectionProfiles.modify.form;

export const selectModifyWorkflowSteps = ({ connectionProfiles }) =>
  connectionProfiles.modify.xtm.workflowSteps;

export const selectModifyXtm = ({ connectionProfiles }) =>
  connectionProfiles.modify.xtm;

export const selectModifyCustomers = ({ connectionProfiles }) =>
  connectionProfiles.modify.customers;

export const selectXtmTokenId = ({ connectionProfiles }) =>
  connectionProfiles.modify.xtm.data.tokenID;

export const selectIsXtmDependenciesLoading = ({ connectionProfiles }) =>
  connectionProfiles.modify.customers.isLoading;

export const selectSharePointFolderPath = ({ connectionProfiles }) =>
  connectionProfiles.modify.form.sharePoint.sharepointFolderPath;

export const selectSharePointTargetPath = ({ connectionProfiles }) =>
  connectionProfiles.modify.form.sharePoint.sharepointTargetPath;

export const selectIsXtmTokenWithoutErrors = ({ connectionProfiles }) =>
  Boolean(
    connectionProfiles.modify.form.xtm.tokenID &&
      !connectionProfiles.modify.xtm.error
  );

export const selectAllFieldsLength = ({ connectionProfiles }) => {
  const { form } = connectionProfiles.modify;
  if (form.connectionType === WORKING_COPY) {
    return allRequiredFieldsFilledWorkingCopy(form);
  }
  if (form.connectionType === HOT_FOLDER) {
    return allRequiredFieldsFilledHotFolder(form);
  }
  return false;
};

export const selectConnectionProfilesSort = ({ connectionProfiles }) =>
  connectionProfiles.list.sort;

export const { setField, resetState, updateSort, clearConnectionProfile } =
  connectionProfilesSlice.actions;

export default connectionProfilesSlice.reducer;
