export const addConnectionProfileMapper = ({ form }) => ({
  integrated_system_name: "Microsoft SharePoint",
  connection_profile_name: form.connectionProfileName,
  connection_type: form.connectionType,
  connection_profile_status: form.active === true ? "ACTIVE" : "INACTIVE",
  client_id: form.clientAssigned,
  xtm_temp_credential: form.xtm.tokenID,
  sharepoint_temp_credential: form.sharePoint.tokenID,
  sharepoint_folder_path: form.sharePoint.sharepointFolderPath,
  xtm_customers_ids: form.xtm.customers,
  periodicity_of_refresh: Number(form.xtm.checkProjectMinutes),
  workflow_step_type_map: form.xtm.workflowSteps.savedWorkflowSteps,
  site_name: form.sharePoint.loginCredentials.sharePointSiteName,
});
