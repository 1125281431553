import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormRow, InputLabel } from "components/global/forms";
import { BorderedSection } from "components/BorderedSection";
import {
  fetchWorkflowStepsAsync,
  selectModifyWorkflowSteps,
  selectXtmTokenId,
} from "data/features/connectionProfiles/connectionProfileSlice";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
/* eslint-disable no-unused-vars */
import { PROCESSING_TYPES } from "./WorkflowStepsConstants";
import { WorkflowSteps } from "./index";

export const WorkflowStepsContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tokenID = useSelector(selectXtmTokenId);
  const workflowSteps = useSelector(selectModifyWorkflowSteps);
  const params = useLocation();
  const profileID = Number(new URLSearchParams(params.search).get("id"));

  useEffect(() => {
    if (!profileID) {
      return;
    }
    dispatch(
      fetchWorkflowStepsAsync({
        id: profileID,
        isProfileId: true,
      })
    );
  }, [profileID]);

  useEffect(() => {
    if (!tokenID) {
      return;
    }
    dispatch(
      fetchWorkflowStepsAsync({
        id: tokenID,
        isProfileId: false,
      })
    );
  }, [tokenID]);

  if (workflowSteps === undefined || workflowSteps.isLoading) return null;

  return (
    <BorderedSection
      inner
      aria-label="WorkflowsSection"
      name={t("connectionProfiles.form.xtm.workflowSteps.sectionName")}
    >
      <FormRow contained bottomSpace groupInner>
        <InputLabel lead>
          {t(
            "connectionProfiles.form.xtm.workflowSteps.preProcessingSteps.label"
          )}
        </InputLabel>
        <WorkflowSteps stepType={PROCESSING_TYPES.preProcessingSteps} />
      </FormRow>
      <FormRow contained bottomSpace groupInner>
        <InputLabel lead>
          {t("connectionProfiles.form.xtm.workflowSteps.ignoredSteps.label")}
        </InputLabel>
        <WorkflowSteps stepType={PROCESSING_TYPES.ignoredSteps} />
      </FormRow>
      <FormRow contained bottomSpace groupInner>
        <InputLabel lead>
          {t("connectionProfiles.form.xtm.workflowSteps.nonCatToolSteps.label")}
        </InputLabel>
        <WorkflowSteps stepType={PROCESSING_TYPES.nonCatToolSteps} />
      </FormRow>
    </BorderedSection>
  );
};
