import axios from "axios";
import { CONNECTION_PROFILE_FORM_TYPES } from "constants";
import { HOT_FOLDER } from "pages/Panel/ConnectionProfiles/constants";
import { addConnectionProfileHotFolderMapper } from "./helpers/addConnectionProfileHotFolderMapper";
import { addConnectionProfileMapper } from "./helpers/addConnectionProfileMapper";

export const fetchConnectionProfileNames = () =>
  axios.get("/api/v1/profile/my-profiles/names");

export const fetchConnectionProfiles = ({
  filter = "",
  page = 0,
  limit = 10,
  sort = { field: "client_name", order: "ASC" },
} = {}) =>
  axios.get("/api/v1/profile/filter", {
    params: {
      filter,
      page: String(page),
      limit: String(limit),
      sort: JSON.stringify(sort),
    },
    headers: {
      "Cache-Control": "no-cache, no-store, max-age=0, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

export const fetchConnectionProfile = ({ id } = {}) =>
  axios.get(`/api/v1/profile/${id}`, {
    headers: {
      "Cache-Control": "no-cache, no-store, max-age=0, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

export const connectToXtm = ({
  username,
  password,
  companyName,
  credentialType,
  url,
}) =>
  axios.post("/api/v1/temp-credential/register", {
    credential_type: credentialType,
    company_name: companyName,
    username,
    password,
    url,
  });

export const connectToSharePoint = ({
  clientID,
  clientSecret,
  url,
  credentialType,
  tenantId,
  siteName,
}) =>
  axios.post("/api/v1/temp-credential/register", {
    credential_type: credentialType,
    client_id: clientID,
    client_secret: clientSecret,
    url,
    tenant_id: tenantId,
    site_name: siteName,
  });

export const fetchCustomers = ({ id, isProfileId = false }) =>
  axios.get(`xtm/customers/${isProfileId ? "profile" : "temp"}/${id}`);

export const fetchWorkflowSteps = ({ id, isProfileId = false }) =>
  axios.get(`xtm/workflow-steps/${isProfileId ? "profile" : "temp"}/${id}`);

export const fetchTemplates = (
  { tempCredentialId, scope, customerIds },
  isProfileId = false
) =>
  axios.get(
    `/xtm/templates/${isProfileId ? "profile" : "temp"}/${tempCredentialId}`,
    {
      params: {
        scope,
        customerIds,
      },
    }
  );

export const submitConnectionProfile = ({ form, workflowsMap, type, id }) => {
  let data = {};
  let connectionTypeUrl = "";
  if (form.connectionType === HOT_FOLDER) {
    connectionTypeUrl = "hot-folder";
    data = addConnectionProfileHotFolderMapper({ form });
  } else {
    connectionTypeUrl = "working-copy";
    data = addConnectionProfileMapper({ form, workflowsMap });
  }

  data.id = id;

  if (
    type === CONNECTION_PROFILE_FORM_TYPES.ADD ||
    type === CONNECTION_PROFILE_FORM_TYPES.CLONE
  ) {
    return axios.post(`/api/v1/profile/${connectionTypeUrl}`, data);
  }

  if (type === CONNECTION_PROFILE_FORM_TYPES.EDIT) {
    return axios.put(`/api/v1/profile/${id}/${connectionTypeUrl}`, data);
  }

  return Promise.reject();
};

export const shouldShowConnectionType = () =>
  axios.get("/api/v1/profile/should-show");
