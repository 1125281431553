import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { InnerSectionContainer } from "components/global/Container";
import { useDispatch, useSelector } from "react-redux";
import { isNumber } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { CONNECTION_PROFILE_FORM_TYPES } from "constants/ConnectionProfilesFormTypes";
import StyledSection from "components/global/Section";
import {
  InputLabel,
  FormRow,
  TextInput,
  StyledCheckbox,
  CancelButton,
  SubmitButton,
} from "components/global/forms";
import { MenuItem, WhiteSelect } from "components/Select";
import {
  fetchConnectionProfileAsync,
  resetState,
  selectAllFieldsLength,
  selectModifyForm,
} from "data/features/connectionProfiles/connectionProfileSlice";
import {
  shouldShowConnectionType,
  submitConnectionProfile,
} from "data/features/connectionProfiles/connectionProfileApi";
import { fetchClients } from "data/features/clients/clientsApi";

import { useTranslation } from "react-i18next";
import { Tooltip } from "components";
import { Box } from "@mui/material";
import { FixedActionsBar } from "../styles";
import { WORKING_COPY, HOT_FOLDER } from "./constants";
import { SharePointSettings } from "./components/SharePointSettings";
import { XTMSettings } from "./components/XTMSettings";
import { handleUpdateState, handleUpdateCheckbox } from "./helpers";
import { Header } from "./components/Header";

export const ConnectionProfileForm = ({ type }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [
    shouldShowConnectionTypeDropdown,
    setShouldShowConnectionTypeDropdown,
  ] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [clients, setClients] = useState([]);
  const form = useSelector(selectModifyForm);

  const params = useLocation();
  const paramID = Number(new URLSearchParams(params.search).get("id"));

  const selectAllFieldsFilled = useSelector(selectAllFieldsLength);

  useEffect(() => {
    fetchClients({ limit: 999, isDropdown: true }).then((res) =>
      setClients(res.data.objects)
    );
  }, []);

  useEffect(() => {
    dispatch(resetState());
    if (type === CONNECTION_PROFILE_FORM_TYPES.ADD) {
      return;
    }
    if (isNumber(paramID)) {
      dispatch(resetState());
      dispatch(fetchConnectionProfileAsync({ id: paramID }));
    }
  }, [paramID]);

  useEffect(() => {
    shouldShowConnectionType()
      .then((response) => {
        const shouldShow = response.data.shouldShowConnectionType;
        setShouldShowConnectionTypeDropdown(shouldShow);

        if (type === CONNECTION_PROFILE_FORM_TYPES.ADD && !shouldShow)
          handleUpdateState("HOT_FOLDER", "connectionType");
      })
      .catch((error) => {
        console.error(error);
        setShouldShowConnectionTypeDropdown(false);
      });
  });

  const onSubmit = (ev) => {
    ev.preventDefault();
    const clientName = clients.find(
      (client) => client.id === form.clientAssigned
    ).name;
    setIsDisabled(true);
    submitConnectionProfile({
      form: { ...form, clientName },
      type,
      id: paramID,
    }).then(() => {
      setIsDisabled(false);
      navigate("/connection-profiles", { replace: true });
    });
  };

  return (
    <StyledSection sx={{ paddingBottom: "70px" }} type="login">
      <form onSubmit={onSubmit}>
        <InnerSectionContainer big withPadding>
          <Header type={type} />
          <FormRow bottomSpace contained>
            <InputLabel lead>
              {t("connectionProfiles.form.profileName.label")}*
            </InputLabel>
            <TextInput
              value={form.connectionProfileName}
              onChange={(ev) =>
                handleUpdateState(ev.target.value, "connectionProfileName")
              }
            />
          </FormRow>
          {shouldShowConnectionTypeDropdown && (
            <FormRow bottomSpace contained>
              <InputLabel lead sx={{ display: "flex", alignItems: "center" }}>
                {t("connectionProfiles.form.connectionType.label")}*
                <Tooltip
                  fullWidth
                  title={
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "2rem",
                        }}
                      >
                        <strong style={{ marginBottom: "0.5rem" }}>
                          Hot folder
                        </strong>
                        Works by automatically fetching files for translation in
                        XTM Cloud. When translation projects are finished in XTM
                        Cloud, the files are automatically resent to the
                        specified destination folder in MS SharePoint.
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <strong style={{ marginBottom: "0.5rem" }}>
                          Working copy
                        </strong>
                        Works by finding relevant XTM Cloud projects and copying
                        the translation files to MS SharePoint. Linguists
                        assigned to jobs in XTM Cloud are then able to work on
                        XLIFF files outside XTM Cloud and their translated
                        content is automatically returned to XTM Cloud (to keep
                        translation memories up to date).
                      </Box>
                    </>
                  }
                  arrow
                  placement="bottom"
                >
                  <InfoIcon
                    sx={{
                      color: "#00A4E4",
                      marginLeft: "0.3rem",
                      fontSize: "1.8rem",
                    }}
                  />
                </Tooltip>
              </InputLabel>
              <WhiteSelect
                onChange={(ev) =>
                  handleUpdateState(ev.target.value, "connectionType")
                }
                value={form.connectionType}
              >
                <MenuItem value={WORKING_COPY}>
                  {t("connectionProfiles.form.connectionType.workingCopy")}
                </MenuItem>
                <MenuItem value={HOT_FOLDER}>
                  {t("connectionProfiles.form.connectionType.hotFolder")}
                </MenuItem>
              </WhiteSelect>
            </FormRow>
          )}
          <FormRow bottomSpace contained>
            <InputLabel lead>{t("form.active.label")}</InputLabel>
            <div>
              <StyledCheckbox
                checked={form.active}
                onChange={(ev) => handleUpdateCheckbox(ev, "active")}
                color="success"
              />
            </div>
          </FormRow>
          <FormRow bottomSpace contained>
            <InputLabel lead>{t("form.clientAssigned.label")}*</InputLabel>
            <WhiteSelect
              onChange={(ev) =>
                handleUpdateState(ev.target.value, "clientAssigned")
              }
              label="Choose..."
              displayEmpty
              value={form.clientAssigned}
              renderValue={(selected) => {
                if (!selected) {
                  return <em>Choose...</em>;
                }
                return clients.find((client) => client.id === selected)?.name;
              }}
            >
              {clients?.map((client) => (
                <MenuItem key={client.id} value={client.id}>
                  {client.name}
                </MenuItem>
              ))}
            </WhiteSelect>
          </FormRow>
        </InnerSectionContainer>
        <InnerSectionContainer noPadding big>
          <SharePointSettings />
        </InnerSectionContainer>
        <InnerSectionContainer noPadding big sx={{ marginTop: "3.2rem" }}>
          <XTMSettings />
        </InnerSectionContainer>
        <FixedActionsBar>
          <CancelButton to="/connection-profiles">
            {t("form.cancel")}
          </CancelButton>
          <SubmitButton
            disabled={!selectAllFieldsFilled || isDisabled}
            type="submit"
          >
            {t("form.save")}
          </SubmitButton>
        </FixedActionsBar>
      </form>
    </StyledSection>
  );
};

ConnectionProfileForm.propTypes = {
  type: PropTypes.oneOf(Object.values(CONNECTION_PROFILE_FORM_TYPES))
    .isRequired,
};
