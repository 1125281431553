import { CONNECTION_PROFILE_STATUS } from "../../../../constants";

export const fetchConnectionProfileMapper = (data) => ({
  id: data.id,
  connectionProfileName: data.connection_profile_name,
  connectionType: data.connection_type,
  active: data.connection_profile_status === CONNECTION_PROFILE_STATUS.ACTIVE,
  clientAssigned: data.client_id,
  sharePoint: {
    tokenID: null,
    loginCredentials: {
      sharePointUrl: "",
      sharePointClientID: "",
      sharePointClientSecret: "",
      sharePointTenantId: "",
      sharePointSiteName: "",
    },
    sharepointFolderPath: data.sharepoint_folder_path,
  },
  xtm: {
    tokenID: null,
    customers: data.xtm_customers_ids,
    loginCredentials: {
      xtmUrl: "",
      xtmCompanyName: "",
      xtmUser: "",
      xtmPassword: "",
    },

    hotFolders: {
      templateType: null,
      xtmTemplate: null,
      xtmProjectNamePrefix: "",
      defaultDueDate: 0,
      continuousLocalisation: true,
      detectContentSpecificTime: null,
      contentSynchroFromShInterval: "",
      projectCreatedNotify: false,
      projectFinishedNotify: false,
      progressTranslationWorkflowNotify: false,
      projectCancelledNotify: false,
      emailAddress: "",
    },
    workflowSteps: {
      savedWorkflowSteps: data.workflow_step_type_map,
    },
    checkProjectMinutes: data.periodicity_of_refresh,
  },
});
