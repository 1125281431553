import { STATE_KEYS_TO_PROCESSING_TYPES } from "./WorkflowStepsConstants";

export const handleDelete = (
  selectedStepIds,
  data,
  handleUpdateState,
  stepType,
  value
) => {
  const newSelectedStepIds = selectedStepIds.filter((id) => id !== value);
  handleUpdateState(newSelectedStepIds, `xtm.workflowSteps.${stepType}`);

  const { [value]: _, ...remainingSavedSteps } =
    data.xtm.workflowSteps.savedWorkflowSteps;
  handleUpdateState(
    remainingSavedSteps,
    "xtm.workflowSteps.savedWorkflowSteps"
  );
};

export const handleChange = (
  selectedStepIds,
  handleUpdateState,
  stepType,
  data,
  ev
) => {
  const selectedValues = Array.isArray(ev.target.value)
    ? ev.target.value
    : [ev.target.value];
  const newSelectedStepIds = Array.from(
    new Set([...selectedStepIds, ...selectedValues])
  );

  handleUpdateState(newSelectedStepIds, `xtm.workflowSteps.${stepType}`);

  const updatedSavedSteps = selectedValues.reduce((acc, id) => {
    if (!acc[id]) {
      return {
        ...acc,
        [id]: STATE_KEYS_TO_PROCESSING_TYPES[stepType],
      };
    }
    return acc;
  }, {});

  const finalSavedSteps = {
    ...data.xtm.workflowSteps.savedWorkflowSteps,
    ...updatedSavedSteps,
  };

  handleUpdateState(finalSavedSteps, "xtm.workflowSteps.savedWorkflowSteps");
};
