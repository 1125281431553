import React, { useEffect } from "react";
import { MenuItem, WhiteSelect } from "components/Select";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { handleDelete, handleChange } from "./WorkflowStepsUtils";
import { Selected } from "./Selected";
import {
  selectModifyForm,
  selectModifyWorkflowSteps,
} from "../../../../../data";
import { handleUpdateState } from "../../helpers";
import { STATE_KEYS_TO_PROCESSING_TYPES } from "./WorkflowStepsConstants";

export const WorkflowSteps = ({ stepType }) => {
  const data = useSelector(selectModifyForm);
  const allWorkflowSteps = useSelector(selectModifyWorkflowSteps).data;
  const allWorkflowStepsMap = new Map(
    allWorkflowSteps.map((step) => [step.id, step])
  );

  const selectedStepIds = data.xtm.workflowSteps[stepType] || [];

  const {
    preProcessingSteps = [],
    ignoredSteps = [],
    nonCatToolSteps = [],
  } = data.xtm.workflowSteps;
  const allSelectedIds = [
    ...new Set([...preProcessingSteps, ...ignoredSteps, ...nonCatToolSteps]),
  ];

  const onHandleChange = (ev) => {
    handleChange(selectedStepIds, handleUpdateState, stepType, data, ev);
  };

  const onHandleDelete = (value) => {
    handleDelete(selectedStepIds, data, handleUpdateState, stepType, value);
  };

  useEffect(() => {
    if (data.xtm.workflowSteps.savedWorkflowSteps) {
      const initialSelectedSteps = Object.keys(
        data.xtm.workflowSteps.savedWorkflowSteps
      )
        .filter(
          (key) =>
            data.xtm.workflowSteps.savedWorkflowSteps[key] ===
            STATE_KEYS_TO_PROCESSING_TYPES[stepType]
        )
        .map(Number);

      handleUpdateState(initialSelectedSteps, `xtm.workflowSteps.${stepType}`);
    }
  }, [data.workflow_step_type_map, stepType]);

  const selectedWorkflowSteps = Selected({
    listOfIds: selectedStepIds,
    mappedData: allWorkflowStepsMap,
    propertyKey: `xtm.workflowSteps.${stepType}`,
    onDelete: onHandleDelete,
  });

  const availableSteps = allWorkflowSteps.filter(
    (step) =>
      !selectedStepIds.includes(step.id) && !allSelectedIds.includes(step.id)
  );

  return (
    <WhiteSelect
      multiple
      value={selectedStepIds || []}
      onChange={onHandleChange}
      renderValue={selectedWorkflowSteps}
    >
      {availableSteps.map((step) => (
        <MenuItem key={step.id} value={step.id}>
          {step.name}
        </MenuItem>
      ))}
    </WhiteSelect>
  );
};

WorkflowSteps.propTypes = {
  stepType: PropTypes.string.isRequired,
};
